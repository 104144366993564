body, html {
    margin: 0;
    height: 100%;
    width: 100%;
}

.pfp {
    margin: unset; 
    background-color: rgb(221, 221, 221); 
    border-radius: 100px; 
    width: 128px; 
    min-height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 20px;
}

.name {
    font-family: poppins;
    letter-spacing: -0.769231px;
    line-height: 48.3333px;
    font-size: 48px;
    text-align: center;
    margin: 0;
}

.subtitle {
    font-family: poppins;
    letter-spacing: 0px; 
    line-height: 26.4px; 
    font-size: 22px; 
    text-align: center;
    font-weight: normal;
    padding-right: 1em;
    padding-left: 1em;
}

.contactButton {
    font-family: poppins;
    border: 0px;
    font-size: 16px; 
    width: 228px;
    min-height: 58px; 
    color: rgb(250, 250, 250); 
    padding: 16px 32px;
    border-radius: 100px;
    background-color: rgb(58, 118, 133); 
    transition-timing-function: ease-in; 
    transition-duration: 0.3s;
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    font-weight: 500;
    margin-top: 20px;
}

.contactButton:active {
    background-color: rgb(52, 106, 120) !important; 
    color: rgb(255, 255, 255) !important; 
    transition-timing-function: ease-in; 
    transition-duration: 0.3s;
}

.contactButton:hover {
    color: rgb(255, 255, 255); 
    background-color: rgb(43, 89, 100); 
    transition-timing-function: ease-in; 
    transition-duration: 0.3s;
}

.projectsButton {
    font-family: poppins;
    border: 0px;
    font-size: 16px; 
    width: 228px;
    min-height: 58px; 
    color: rgb(250, 250, 250); 
    padding: 16px 32px;
    border-radius: 100px;
    background-color: rgb(11, 46, 130); 
    box-shadow: 5px 4px 26px 1px rgba(0, 0, 0, 0.24);
    transition-timing-function: ease-in; 
    transition-duration: 0.3s;
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    font-weight: 500;
    margin-top: 20px;
}

.projectsButton:active {
    background-color: rgb(47, 34, 161) !important; 
    color: rgb(255, 255, 255) !important; 
    transition-timing-function: ease-in;
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.projectsButton:hover {
    color: rgb(255, 255, 255); 
    background-color: rgb(24, 13, 122); 
    transition-timing-function: ease-in; 
}

.projectsHeader {
    font-family: poppins;
    background-color: rgba(0, 0, 0, 0); 
    text-align: center;
    font-weight: 500; 
    font-style: normal; 
    letter-spacing: -0.673077px;
    line-height: 42.2917px; 
    font-size: 35px;
    padding-top: 2rem;
}

.projects {
    max-width: 45rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    flex-direction: column;
    display: flex;
    gap: 1.5rem;
}

.projectContainer {
    cursor: pointer;
    background-color: rgb(255, 255, 255); 
    padding: 25px;
    border-style: solid; 
    border-width: 1px; 
    border-radius: 5px; 
    border-color: rgba(100, 98, 98, 0.15); 
    box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.02);
    display: flex;
    gap: 2rem;
    align-items: center;
}

.projectContainer:hover {
    border-color: rgba(100, 98, 98, 0.5);
    transition-timing-function: ease-in; 
    transition-duration: 0.2s;
}

.projectContainer:not(:hover) {
    border-color: rgba(100, 98, 98, 0.15);
    transition-timing-function: ease-in; 
    transition-duration: 0.1s;
}

.projectContainer:active {
    border-color: rgba(157, 153, 153, 0.5);
}

.logo {
    max-width: 55px;
}

.projectTitle {
    font-family: poppins;
    line-height: 27px; 
    font-size: 24px; 
    font-weight: 600;
    font-style: normal; 
    top: -9px;
    letter-spacing: 0px;
    margin: unset; 
    background-color: rgba(0, 0, 0, 0);
    padding: 5px;
}

.projectDescription {
    margin: unset; 
    background-color: rgba(0, 0, 0, 0); 
    font-family: poppins; 
    letter-spacing: 0px; 
    line-height: 20.25px; 
    font-size: 18px; 
    font-weight: 300; 
    font-style: normal;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 15px;
}

.projectLanguage {
    margin: unset; 
    background-color: rgba(0, 0, 0, 0); 
    font-family: poppins; 
    letter-spacing: 0px; 
    line-height: 15.75px; 
    font-size: 14px; 
    font-weight: 600; 
    font-style: normal; 
    top: -9px;
    padding: 5px;
}

.githubButton {
    font-family: poppins;
    margin: unset; 
    font-size: 16px; 
    width: 228px; 
    min-height: 58px; 
    color: rgb(250, 250, 250); 
    padding: 16px 32px; 
    border-radius: 100px; 
    background-color: rgb(35, 35, 35); 
    box-shadow: 5px 4px 26px 1px rgba(0, 0, 0, 0.24);
    top: -9px; transition-timing-function: 
    ease-in; transition-duration: 0.3s;
    border: 0px;
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3em;
}

.githubButton:active {
    background-color: rgb(35, 35, 35) !important; 
    color: rgb(255, 255, 255) !important; 
    transition-timing-function: ease-in;
    transition-duration: 0.3s;
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.githubButton:hover {
    color: rgb(255, 255, 255); 
    background-color: rgb(0, 0, 0); 
    transition-timing-function: ease-in; 
    transition-duration: 0.3s;
}

.homePage {
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 10vh;
}

.projectsPage {
    width: 100vw;
    box-sizing: border-box;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 3.5rem;
}

.homeButtons {
    display: flex;
    gap: 1em;
}

@media (max-width: 800px) {
    .homeButtons {
      flex-direction: column-reverse;
      gap: 0;
    }
  }

.backButton {
    position: absolute;
    top: 0; 
    left: 0;
    padding: 1em;
    cursor: pointer;
}

.socials {
    display: flex;
    gap: 2em;
    margin-top: 1.5em;
}